import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.dark.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Error from './Error';
import BodyMain from './components/BodyMain';
import Applications from './components/Applications';
import Responsibility from './components/Responsibility';
import ProductCategories from './components/ProductCategories';
import Technology from './components/Technology';
import App from './App';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <BodyMain />,
      },
      {
        path: "applications",
        element: <Applications />,
      },
      {
        path: "technology",
        element: <Technology />,
      },
      {
        path: "categories",
        element: <ProductCategories />,
      },
      {
        path: "responsibility",
        element: <Responsibility />,
      }
      
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
