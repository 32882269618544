import { MDBAccordion, MDBAccordionItem, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from 'react';
import Header from "../utils/Header";
import { Helmet } from 'react-helmet';

/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
class ProductCategories extends React.Component {

    render() {
        return (
            < div className='p-1 mb-4' >
                <Helmet>
                    <title>Artificial Intelligence Product Categories</title>
                    <meta name="description" content="Artificial Intelligence Product Categories" />
                    <meta name="keywords" content="Artificial Intelligence Product Categories" />
                    <meta name="author" content="AVision" />
                    <meta property="og:title" content="Artificial Intelligence Product Categories" />
                    <meta property="og:description" content="Artificial Intelligence Product Categories" />
                    <meta property="og:image" content="https://example.com/image.jpg" />
                    <meta property="og:url" content="https://example.com/my-page" />
                    <meta name="twitter:title" content="Artificial Intelligence Product Categories" />
                    <meta name="twitter:description" content="Artificial Intelligence Product Categories" />
                    <meta name="twitter:image" content="https://example.com/image.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <Header
                    url={"url('/images/header_main.png"}
                    header={"AVision World"}
                    desc={"Smart Solutions: AI-Enhanced Analytics"}
                />
                <MDBRow>
                    <MDBCol size='md-3'>
                        <MDBCard>
                            <MDBCardImage
                                src=
                                ""
                                position="top"
                            />
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-2">AI Cyber Security</p></MDBCardTitle>
                                <MDBCardText>
                                    OpenAI on your data enables you to run supported chat models such as GPT-35-Turbo and GPT-4 on your data without needing to train or fine-tune models.
                                    Running models on your data enables you to chat on top of, and analyse your data with greater accuracy and speed. By doing so, you can unlock valuable insights that can help you make better business decisions, identify trends and patterns, and optimize your operations. One of the key benefits of OpenAI on your data is its ability to tailor the content of conversational AI.
                                    Because the model has access to, and can reference specific sources to support its responses, answers are not only based on its pretrained knowledge but also on the latest information available in the designated data source. This grounding data also helps the model avoid generating responses based on outdated or incorrect information.
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol size='md'>
                        <MDBCard>
                            <MDBCardImage
                                src=
                                ""
                                position="top"
                            />
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-2">AVision World</p></MDBCardTitle>
                                <MDBCardText>
                                    Artificial Intelligence (AI) products span a wide range of categories, reflecting the technology's versatility and its integration into various aspects of daily life and industry sectors.
                                    <br /><br />
                                    Here are some of the major categories of AI products:

                                    <p className="header-3">AI Platforms</p> These are frameworks and environments that allow for the development of AI models and applications. They typically provide tools for machine learning, deep learning, data manipulation, and analytics. Examples include Google AI Platform, Microsoft Azure Machine Learning, and IBM Watson.
                                    <p className="header-3">Natural Language Processing (NLP) Tools</p>NLP products focus on understanding, interpreting, and generating human language. This category includes chatbots, language translation services, sentiment analysis tools, and content generation platforms.
                                    <p className="header-3">Machine Learning Libraries and Frameworks</p>These libraries and frameworks provide pre-built algorithms and neural network architectures to facilitate the development of machine learning models. Popular examples include TensorFlow, PyTorch, and Scikit-learn.
                                    <p className="header-3">Computer Vision Applications</p>These tools use AI to analyze data and make predictions about future trends, behaviors, and outcomes. They are widely used in finance, marketing, healthcare, and supply chain management.
                                    <p className="header-3">AI for Cybersecurity</p>AI products designed to enhance cybersecurity efforts, including threat detection systems, network security monitoring tools, and fraud detection applications.
                                    <p className="header-3">Personal AI Assistants</p>Virtual assistants that use AI to perform tasks for users, such as Siri, Alexa, and Google Assistant. They can handle a variety of functions like setting reminders, answering questions, and controlling smart home devices.
                                    <br /><br />Each of these categories demonstrates the breadth of AI's application across different sectors, offering solutions that range from improving operational efficiency to enhancing user experiences and driving innovation. The development and adoption of AI products continue to grow, promising even more advanced and integrated solutions in the future.
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol size="md-3">
                        <MDBAccordion initialActive={1}>
                            <MDBAccordionItem collapseId={1} headerTitle={<p className="header-2">AI Data Analytics</p>}>
                                AI search refers to the application of artificial intelligence techniques to the process of searching and retrieving information. This can involve searching within databases, the internet, or specific datasets. AI search systems are designed to go beyond traditional keyword-based searches, employing more sophisticated methods to understand the context, interpret the intent behind queries, and deliver more accurate, relevant results. These systems can learn from data, improving their search capabilities over time through machine learning algorithms.
                            </MDBAccordionItem>
                            <MDBAccordionItem collapseId={2} headerTitle={<p className="header-2">AI Video, Image and Audio Analytics</p>}>
                                Bots and digital assistants are software applications designed to perform tasks or services for an individual, often using the internet and other communication technologies. They operate on a range of platforms, including websites, messaging apps, and smart devices. While both are automated solutions that interact with users, there are distinct differences in their complexity, capabilities, and applications.
                            </MDBAccordionItem>
                            <MDBAccordionItem collapseId={3} headerTitle={<p className="header-2">AI Digital Twins</p>}>
                                Data analytics with AI refers to the process of analyzing data sets to extract valuable insights, make predictions, or generate recommendations, using artificial intelligence (AI) technologies. This approach leverages various AI techniques, including machine learning, deep learning, natural language processing (NLP), and more, to enhance the capabilities of traditional data analytics by enabling more efficient data processing, uncovering deeper insights, and automating complex analytical tasks.
                            </MDBAccordionItem>
                        </MDBAccordion>
                    </MDBCol>
                </MDBRow>
            </div >
        )
    }
}

export default ProductCategories;