import { MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { NavLink } from "react-router-dom";
/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
class Footer extends React.Component {
    render() {
        return (

            <MDBFooter className='text-center text-lg-start text-muted'>
                <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                    <div className='me-5 d-none d-lg-block'>
                        <span className="header-2">Get connected with us on social networks:</span>
                    </div>

                    <div className="header-2">
                        <NavLink to='https://wa.me/971585522636' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                            <MDBIcon fab icon="whatsapp" />
                        </NavLink>

                        <a href='https://twitter.com/SoftwareAvision' className='me-4 text-reset'>
                            <MDBIcon fab icon="twitter" />
                        </a>
                        <a href='https://www.youtube.com/@avisionsoftware4886' className='me-4 text-reset'>
                            <MDBIcon fab icon="google" />
                        </a>
                        <a href='https://www.instagram.com/avisionsoftware/' className='me-4 text-reset'>
                            <MDBIcon fab icon="instagram" />
                        </a>
                        <a href='https://www.linkedin.com/company/avisionsoftware/' className='me-4 text-reset'>
                            <MDBIcon fab icon="linkedin" />
                        </a>
                        <a href='https://github.com/AVision-Software' className='me-4 text-reset'>
                            <MDBIcon fab icon="github" />
                        </a>
                    </div>
                </section>

                <section className=''>
                    <MDBContainer className='text-center text-md-start mt-5'>
                        <MDBRow className='mt-3'>
                            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>
                                    <MDBIcon icon="gem" className="me-3" />
                                    AVision Software
                                </h6>
                                <p>
                                    At AVision Software, our vision is to pioneer the frontier of artificial intelligence technology, creating innovative software solutions that empower businesses and individuals to unlock their full potential.
                                </p>
                            </MDBCol>

                            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>Products</h6>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Chatbots
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Digital Assitants
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Data Analytics
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Custom Solutions
                                    </a>
                                </p>
                            </MDBCol>

                            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>Models</h6>
                                <p>
                                    <NavLink to='https://openai.com/gpt-4' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                                        GPT-4
                                    </NavLink>
                                </p>
                                <p>
                                    <NavLink to='https://openai.com/blog/introducing-text-and-code-embeddings' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                                        Embedding
                                    </NavLink>
                                </p>
                                <p>
                                    <NavLink to='https://openai.com/dall-e-3' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                                        DALL·E 3
                                    </NavLink>
                                </p>
                                <p>
                                    <NavLink to='https://openai.com/research/whisper' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                                        Whisper
                                    </NavLink>
                                </p>
                            </MDBCol>

                            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>Contact</h6>
                                <p>
                                    <MDBIcon icon="home" className="me-3" />Dubai Internet City, Dubai, UAE
                                </p>
                                <p>
                                    <MDBIcon icon="envelope" className="me-3" />ai@avisionsoftware.com
                                </p>
                                <p>
                                    <MDBIcon icon="phone" className="me-3" />+971 58 552 2636
                                </p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </section>

                <div className='text-center p-4 header-2' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                    © 2024 Copyright:
                    <a className='text-reset fw-bold' href='http://www.avisionsoftware.com/'>
                        AVisionSoftware.com
                    </a>
                </div>
            </MDBFooter>
        )
    }
}

export default Footer;